import { useState, useCallback, useEffect } from 'react'
import Img from 'next/image'

import Router from 'next/router'
import Layout from '../components/layout'
import { Magic } from 'magic-sdk'
import { OAuthExtension } from '@magic-ext/oauth'
import { useUser } from 'lib/hooks'

const Login = () => {
  const { isFinished, user } = useUser({ redirectIfFound: '/home' })
  const [isSubmitting, setIsSubmitting] = useState(false)
  // const [isLoadFinished, setIsLoadFinished] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  // useEffect(() => {
  //   if (isFinished) {
  //     setIsLoadFinished(true)
  //   }
  // }, [isFinished])

  const googlog = useCallback(async () => {
    try {
      const magic = new Magic(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY, {
        locale: 'ja',
        extensions: [new OAuthExtension()],
      })
      const res = await magic.oauth.loginWithRedirect({
        provider: 'google',
        redirectURI:
          (process.env.NEXT_PUBLIC_LOCAL_DEV_URL &&
            `http://${process.env.NEXT_PUBLIC_LOCAL_DEV_URL}/callback`) ||
          `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/callback`,
      })
    } catch (error) {
      console.error('An unexpected error happened occurred:', error)
      setErrorMsg(error.message)
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    if (errorMsg) setErrorMsg('')
    const body = {
      email: e.currentTarget.email.value,
    }

    try {
      const magic = new Magic(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY, {
        locale: 'ja',
      })
      const didToken = await magic.auth.loginWithMagicLink({
        email: body.email,
        redirectURI: `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/callback`,
      })
      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + didToken,
        },
        body: JSON.stringify(body),
      })
      if (res.status === 200) {
        setIsSubmitting(false)
        Router.push('/home')
      } else {
        setIsSubmitting(false)
        throw new Error(await res.text())
      }
    } catch (error) {
      setIsSubmitting(false)
      console.error('An unexpected error happened occurred:', error)
      setErrorMsg(error.message)
    }
  }

  if (!isFinished) {
    return <></>
  }

  return (
    <>
      {isFinished && !user && (
        <Layout>
          <div className="flex flex-col items-center justify-center mx-4">
            <h1 className="text-gray-800">ログイン・登録</h1>
            <p className="mt-8 mb-8 text-sm ">
              保護者のメールアドレスを登録してください。
              <br />
              今後学園とのやり取りには登録されたメールアドレスを使用いたします。
            </p>

            <button
              className="flex items-center  border border-[#4185f4] border-solid rounded hover:border-blue-600"
              onClick={() => googlog()}>
              <div className="ml-2 flex items-center">
                <Img
                  src="/google_logo.svg"
                  layout="fixed"
                  width="26"
                  height="26"
                  alt="Google Logo"
                />
              </div>
              <span className="ml-2 py-3 px-2 font-semibold text-sm text-gray-50 bg-[#4185f4]">
                Google でログイン・登録
              </span>
            </button>
            <span className="my-4 text-gray-700 text-sm">または</span>
            <div className="w-full  max-w-sm">
              <form onSubmit={handleSubmit}>
                <label className="form-label">メールアドレス</label>
                <input
                  className={`form-input ${isSubmitting && ''}`}
                  type="email"
                  name="email"
                  required
                  disabled={isSubmitting}
                  placeholder="ikuei@example.com"
                />

                <div className="mt-4 text-center">
                  <button
                    className={`btn-blue ${isSubmitting && 'bg-gray-800'}`}
                    type="submit"
                    disabled={isSubmitting}>
                    ログイン・登録
                  </button>
                </div>
                <p className="mt-4 text-xs text-gray-500 text-center">
                  クリック後にログインリンクがメールで届きます。
                  <br />
                  メールボックスをご確認ください。
                </p>
              </form>
            </div>
          </div>
        </Layout>
      )}
    </>
  )
}

export default Login
